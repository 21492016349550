import React from "react";
import styled from "styled-components";
import { device, colors } from '../../utils/styles';

const TitleCard = styled.h2`
  text-align: ${props => (props.left ? "left" : "center")};
  color: ${colors.sscBlue};
  font-family: uzura;
  font-size: 38px;
  font-weight: 700;
  margin: 20px 10px;
`;

const SubTitleStyled = styled.span`
  font-size: 18px;
  margin-left: 10px;
  font-weight: 400;
  & ::before {
      content: "|";
    }
  color: ${colors.sscRed};
`;

const MainTitle = props => {
  const {title, subTitle, ...rest } = props;
  return (
  <TitleCard {...rest}>
    {title}
    { subTitle && <SubTitleStyled> {subTitle}</SubTitleStyled>}
  </TitleCard>
  );
};

export default MainTitle;

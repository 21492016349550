import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import Section from "../common/Section/Section";
import MainTitle from "../common/MainTitle/MainTitle";
import Layout from "../Components/Layout";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { getVacationDates, formatDateInJapanese } from "../utils/utils";
import { device, colors } from '../utils/styles';

const links = ["トップ"];

const ModalContent = styled.div`
  & img {
    max-width:100%;
  }
`;

const StyledH3 = styled.h3`
  background-color:rgba(0,0,0,0.5);
  color:white;
  border-radius: 10px;
  @media ${device.mobileS} { 
    font-size:0.75em;
  }
  @media ${device.tablet} {
    font-size:1.75rem;
  }
`;

const Top = () => {

  const [show, setShow] = useState(false);
  const [info, setInfo] = useState("");
  const [calendarEvents, setCalendarEvents] = useState([]);

  const updateEventsForThisMonth = () => {

    const events = [];

    // vacation days + payment limit
    getVacationDates().map(elm => events.push(elm));

    // Add events from prismic
    const externalEvents = data.prismicEvents.data.events_list;
    if (externalEvents.length > 0){
      externalEvents
      .filter(elm => elm.is_displayed)
      .forEach(element => {
        events.push({
          color: element.color ? element.color: '',
          title: element.title,
          date: element.event_date,
        });
      });
    }
    //return events;
    setCalendarEvents(events);
  };

  useEffect(() => {
    updateEventsForThisMonth();
  }, []);

  const handleClose = () => setShow(false);

  const handleShow = info => {
    setInfo(info);
    return setShow(true);
  };

  const data = useStaticQuery(graphql`
    {
      prismicTopPage {
        id
        last_publication_date
        data {
          cards {
            card_image {
              url
            }
            description {
              html
              text
            }
            title {
              html
              text
            }
          }
          carousel {
            image {
              url
            }
            title
          }
        }
      }
      prismicNews {
        id
        last_publication_date
        data {
          news_list {
            date
            content {
              html
              text
            }
            title
            is_displayed
          }
        }
      }
      prismicEvents {
        id
        data {
          events_list {
            event_date
            title
            is_displayed
            color
          }
        }
      }
    }
  `);

  const CardTextStyled = styled(Card.Text)`
    height: 80px;
  `;

  return (
    <React.Fragment>
      <Layout
        title="狭山スイミングクラブ"
        seoTitle="狭山スイミングクラブ正式サイト"
        seoDescrption="狭山スイミングクラブ正式サイト: 沢山の思い出を一緒に作りましょう　！"
        seoKeywords="狭山スイミングクラブ,狭山プール,所沢,水泳,入間,狭山スポーツ,狭山,埼玉プール,所沢プール"
      >
        <Section className="d-none d-lg-block">
          <Container>
            <Carousel fade>
              {data.prismicTopPage.data.carousel.map(currentElement => {
                return (
                  <Carousel.Item key="k">
                    <img
                      className="d-block w-100"
                      src={currentElement.image.url}
                      alt={currentElement.title}
                    />
                    <Carousel.Caption>
                      <StyledH3>{currentElement.title}</StyledH3>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Container>
        </Section>

        {/* 3 cards */}
        <Section parallax>
          <Container>
            <Row>
              {data.prismicTopPage.data.cards.map(currentElement => {
                return (
                  <Col sm={12} md={4} style={{marginBottom: "15px"}}>
                    <Card style={{ width: "100%", border: `solid 1px ${colors.sscGray}` }}>
                      <Card.Img
                        variant="top"
                        src={currentElement.card_image.url}
                      />
                      <Card.Body>
                        <Card.Title style={{color: colors.sscBlue}}>{currentElement.title.text}</Card.Title>
                        <CardTextStyled>
                          <span dangerouslySetInnerHTML={{__html: currentElement.description.html}}/>
                        </CardTextStyled>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </Section>

        <Section>
          <Container>
            <Row>
              {/* news */}
              <Col sm={12} md={6} style={{marginBottom: "20px"}}>
                <MainTitle title="News" subTitle="お知らせ" />
                  {data.prismicNews.data.news_list
                  .filter(elm => elm.is_displayed)
                  .sort( (a,b) => new Date(b.date) - new Date(a.date))
                  .map( (info, idx) => {
                    const dateString = formatDateInJapanese(new Date(info.date));
                    return (
                        <Row style={{marginBottom: '5px', marginTop: '5px', paddingBottom: '5px', paddingTop: '5px', borderBottom: `1px dotted ${colors.sscBlue}` }}>
                          <Col sm={12} >
                            <>
                              <span style={{color: '#1b85d6', fontSize: '1.3rem', fontFamily: 'Georgia, "Times New Roman", Times, serif'}}>
                                {dateString}
                              </span>
                              {idx < 2 &&
                                <span style={{backgroundColor: 'orange', color: 'white', borderRadius: '5px', padding: '0 10px', margin: '0 10px', fontSize: '0.8rem'}}>
                                新着
                                </span>
                              }
                            </>
                          </Col>
                          <Col sm={12} style={{ cursor: "pointer", fontSize: '1.1rem'}} onClick={e => handleShow(info)} >{info.title}</Col>
                        </Row>
                    );
                  })}
                  
              </Col>
              <Col sm={12} md={6}>
              <MainTitle title="Events" subTitle="イベント" />
                <div>
                  <FullCalendar
                    defaultView="dayGridMonth"
                    plugins={[dayGridPlugin]}
                    events={calendarEvents}
                    locale="ja"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>

      {/* modal for news */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{info.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent dangerouslySetInnerHTML={{ __html: info.content?.html }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Top;

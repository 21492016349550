import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/styles";

const Wrapper = styled.div`
  margin: 30px 0;
  padding: 10px;
  background-color: ${props => (props.grey ? colors.sscSecondBgc : "#fff")};
  border: ${props =>
    props.grey ? `solid 1px ${colors.sscSecondBgcBorder}` : "solid 0px #fff"};

  ${({ parallax }) =>
    parallax &&
    `
    background-image: url("/parallax.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `}
`;

const Section = props => {
  return <Wrapper {...props}>{props.children}</Wrapper>;
};
export default Section;

export const FIRST_END_OF_MONTH_VACATION_DAY = 29;
export const LIMIT_DAY = 28;
export const NUMBER_OF_MONTHS = 5;

const getLastDayOfTheMonth = myDate => {
  return new Date(myDate.getFullYear(), myDate.getMonth() + 1, 0).getDate();
};

export const getVacationDates = () => {
  const vacationDays = [];
  const now = new Date();
  const currentMonth = now.getMonth();
  for (let i = 0; i <= NUMBER_OF_MONTHS ; i++) {
    now.setMonth(currentMonth + i);
    const year = now.getFullYear().toString();
    let month = ('0' + (now.getMonth() + 1).toString()).slice(-2);

    // Set 締め切り
    vacationDays.push({
      color: 'green',
      title: "届出/切",
      date: `${year}-${month}-${LIMIT_DAY}`,
    });

    for (let j = FIRST_END_OF_MONTH_VACATION_DAY; j <= parseInt(getLastDayOfTheMonth(now)); j++) {
      // Set vacation day
      vacationDays.push({
        color: "red",
        title: "休館日",
        date: `${year}-${month}-${j}`,
      });
    }
  }
  return vacationDays;
};

export const formatDateInJapanese = (myDate) => {
  const myYear = myDate.getFullYear();
  const myMonth = ('0' + (myDate.getMonth() + 1).toString()).slice(-2);
  const myDay = ('0' + (myDate.getDate()).toString()).slice(-2);
  return `${myYear}.${myMonth}.${myDay}`;
}
